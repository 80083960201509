import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffaf44',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, coverCrop, freshWeight, freshWeightAc, dryContent, dryWeight, tissueN, totalN, pan28, actualPan28, pan70, actualPan70, bg, fontWeight) {
  return { name, coverCrop, freshWeight, freshWeightAc, dryContent, dryWeight, tissueN, totalN, pan28, actualPan28, pan70, actualPan70, bg, fontWeight };
}

const rows = [
  createData('Winter/Till', 'Bell bean', 0.78, 33977, '10.6%', 3602, '4.20%', 151.26, '64.03%', 0.97, '69.95%', 1.06),
  createData('Winter/Till', 'Austrian Winter Pea', 0.6, 26136, '11.7%', 3058,  '4.90%', 149.84, '63.34%', 0.95, '67.72%', 1.01),
  createData('Winter/Till', 'Annual ryegrass', 0.36, 15682, '13.4%', 2104, '4.72%', 99.33, '54.76%', 0.54, '60.58%', 0.60),
  createData('Winter/Till', 'Woolypod vetch',  0.45, 19602, '11.2%', 2195, '5.32%', 116.80, '58.46%', 0.68, '66.57%', 0.78),
  createData('Winter/Till', 'Oat (Cayuse)', 1.15, 50094, '17.2%', 8616, '2.34%', 201.62, '42.55%', 0.86, '53.28%', 1.07),
  createData('Average', '', '0.67', 29098.08, '12.82%', '', '4.30%', '', 0.56628, '', 0.6362, '', '#f1a896', 'bold'),
];

export default function ReferenceTableLalamilo() {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table" size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan={2}>Cover Cropping Practice</StyledTableCell>
            <StyledTableCell align="center" colSpan={3}>Field</StyledTableCell>
            <StyledTableCell align="center" colSpan={3}>Cover Cropping Tissue</StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>28 Days</StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>70 Days</StyledTableCell>
          </TableRow>
          
          <TableRow sx={{backgroundColor: '#ffefd9'}}>
            <StyledTableCell>Season/tillage</StyledTableCell>
            <StyledTableCell align="right">Cover Crop</StyledTableCell>
            <StyledTableCell align="right">Fresh Weight&nbsp;(lb/ft<sup>2</sup>)</StyledTableCell>
            <StyledTableCell align="right">Fresh Weight&nbsp;(lbs/ac)</StyledTableCell>
            <StyledTableCell align="right">Dry Matter&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Dry Weight&nbsp;(lb/Acre)</StyledTableCell>
            <StyledTableCell align="right">Tissue N&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Total N&nbsp;(lb/A)</StyledTableCell>
            <StyledTableCell align="right">PAN&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Actual PAN&nbsp;(lb/A)</StyledTableCell>
            <StyledTableCell align="right">PAN&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Actual PAN&nbsp;(lb/A)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i} sx={{backgroundColor: row.bg, fontWeight: row.fontWeight}}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.coverCrop}</StyledTableCell>
              <StyledTableCell align="right">{row.freshWeight}</StyledTableCell>
              <StyledTableCell align="right">{row.freshWeightAc}</StyledTableCell>
              <StyledTableCell align="right">{row.dryContent}</StyledTableCell>
              <StyledTableCell align="right">{row.dryWeight}</StyledTableCell>
              <StyledTableCell align="right">{row.tissueN}</StyledTableCell>
              <StyledTableCell align="right">{row.totalN}</StyledTableCell>
              <StyledTableCell align="right">{row.pan28}</StyledTableCell>
              <StyledTableCell align="right">{row.actualPan28}</StyledTableCell>
              <StyledTableCell align="right">{row.pan70}</StyledTableCell>
              <StyledTableCell align="right">{row.actualPan70}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
