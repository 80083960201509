import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import orcd from './images/headers/orcd.png';

export default function Subheader() {
    return (
      <AppBar position="relative" sx={{backgroundColor: '#013826', textAlign: 'center'}}>
        <Container maxWidth="lg">
          <Box sx={{display: { xs: 'block', sm: 'block' }, p: {xs: 1, sm:1}}}>
            <Box>
              <Typography variant="caption" sx={{fontStyle: 'italic', fontWeight: 'light'}}>Hosted by</Typography>
            </Box>
            <Link href="https://oahurcd.org/">
              <Box component="img"
                src={orcd}
                alt={`Hosted by ORCD`}
                loading="lazy"
                sx={{objectFit: `contain`, height: {xs: '40px', sm: '50px', md: '60px'}}}
              />
            </Link>
          </Box>
        </Container>
      </AppBar>
    );
  }