import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';

import logo from './images/headers/logo.png';
import crate from './images/headers/crate_white.png';
import soap from './images/headers/soap.png';

export default function Header() {
    return (
      <AppBar position="relative" sx={{backgroundColor: '#004730'}}>
        <Container maxWidth="lg">
          <Box sx={{display: { xs: 'block', sm: 'block' }, p: {xs: 1, sm:2}}}>
            <Box component="img"
              src={logo}
              alt={`CTHAR`}
              loading="lazy"
              sx={{objectFit: `contain`, height: {xs: '45px', sm: '67px', md: '103px'}, mr: {xs: 1, sm: 1, md: 2}}}
            />
            <Box component="img"
              src={crate}
              alt={`CRATE`}
              loading="lazy"
              sx={{objectFit: `contain`, height: {xs: '45px', sm: '67px', md: '103px'}, mr: {xs: 0, sm: 1, md: 2}}}
            />
            <Box component="img"
              src={soap}
              alt={`SOAP`}
              loading="lazy"
              sx={{objectFit: `contain`, height: {xs: '45px', sm: '67px', md: '103px'}}}
            />
          </Box>
        </Container>
      </AppBar>
    );
  }