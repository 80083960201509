import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';

export default function LocationSelect(props) {
  const { locations, onChange } = props;
  const [index, setIndex] = React.useState(0);

  const handleChange = (event) => {
    setIndex(event.target.value);
    onChange(event)
  };

  return (
    <Box sx={{ minWidth: 120, mt: 1 }} component="form">
      <FormControl fullWidth variant="filled">
        <InputLabel id="location-and-soil">Location and Soil</InputLabel>
        <Select
          labelId="location-and-soil"
          value={index}
          label="Location and Soil"
          onChange={handleChange}
        >
          {locations.map((location, i) => (
            <MenuItem key={i} value={i}>{location.island} - {location.location} - {location.soilOrder}</MenuItem>
          ))}
        </Select>
        <FormHelperText>
          Select the location and soil order that best matches your area. 
          To find the soil order of your area, check out the <Link href="https://casoilresource.lawr.ucdavis.edu/gmap/">SoilWeb Map</Link>.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
