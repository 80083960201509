import { red, teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// The theme to somewhat match University of Hawaii colors
const theme = createTheme({
  palette: {
    primary: teal,
    secondary: teal,
    // primary: {
    //   main: '#556cd6',
    // },
    // secondary: {
    //   main: '#19857b',
    // },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
