export const OahuPoamohoOxisols = Object.freeze({
    island: 'Oahu',
    location: 'Poamoho',
    soilOrder: 'Oxisols',
    coefficients28: {
        degreeTwo: 0.562,
        degreeOne: 1.6376,
        degreeZero: 52.677 
    },
    coefficients70: {
        degreeTwo: -0.5346,
        degreeOne: 9.4219,
        degreeZero: 53.371 
    }
})
export const OahuWaimanaloMollisols = Object.freeze({
    island: 'Oahu',
    location: 'Waimanalo',
    soilOrder: 'Mollisols', 
    coefficients28: {
        degreeTwo: -0.182,
        degreeOne: 41.686,
        degreeZero: -72.766
    },
    coefficients70: {
        degreeTwo: -27.359,
        degreeOne: 149.89,
        degreeZero: -152.17
    }
})
export const OahuKuniaOxisols = Object.freeze({
    island: 'Oahu',
    location: 'Kunia',
    soilOrder: 'Oxisols', 
    coefficients28: {
        degreeTwo: -12.948,
        degreeOne: 77.68,
        degreeZero: -90.368
    },
    coefficients70: {
        degreeTwo: -15.814,
        degreeOne: 94.624,
        degreeZero: -106.89
    }
})
export const HawaiiWaimeaAndisols = Object.freeze({
    island: 'Hawaii',
    location: 'Waimea',
    soilOrder: 'Andisols', 
    coefficients28: {
        degreeTwo: -1.9606,
        degreeOne: 22.745,
        degreeZero: -3.3735
    },
    coefficients70: {
        degreeTwo: -3.0677,
        degreeOne: 29.991,
        degreeZero: -6.0594
    }
})
export const KauaiLihueOxisols = Object.freeze({
    island: 'Kauai',
    location: 'Lihue',
    soilOrder: 'Oxisols', 
    coefficients28: {
        degreeTwo: -0.2816,
        degreeOne: 8.6031,
        degreeZero: -13.157
    },
    coefficients70: {
        degreeTwo: 1.7427,
        degreeOne: -3.1886,
        degreeZero: 10.069
    }
})
export const MauiAlaeAndisols = Object.freeze({
    island: 'Maui',
    location: 'Alae',
    soilOrder: 'Andisols', 
    coefficients28: {
        degreeTwo: -15.852,
        degreeOne: 98.617,
        degreeZero: -115.12
    },
    coefficients70: {
        degreeTwo: -22.557,
        degreeOne: 122.5,
        degreeZero: -129.12
    }
})
export const MauiKulaAndisols = Object.freeze({
    island: 'Maui',
    location: 'Kula',
    soilOrder: 'Andisols', 
    coefficients28: {
        degreeTwo: -13.596,
        degreeOne: 99.847,
        degreeZero: -111.57
    },
    coefficients70: {
        degreeTwo: -21.093,
        degreeOne: 141.72,
        degreeZero: -150.69
    }
})
export const MauiMokuleleMollisols = Object.freeze({
    island: 'Maui',
    location: 'Mokulele',
    soilOrder: 'Mollisols', 
    coefficients28: {
        degreeTwo: -2.096,
        degreeOne: 17.779,
        degreeZero: -35.27
    },
    coefficients70: {
        degreeTwo: -0.8505,
        degreeOne: 10.622,
        degreeZero: -20.349
    }
})
export const MauiWaiakoaMollisols = Object.freeze({
    island: 'Maui',
    location: 'Waiakoa',
    soilOrder: 'Mollisols', 
    coefficients28: {
        degreeTwo: -25.945,
        degreeOne: 149.49,
        degreeZero: -184.53
    },
    coefficients70: {
        degreeTwo: -12.925,
        degreeOne: 75.504,
        degreeZero: -75.778
    }
})
export const MolokaiHoolehuaInceptisols = Object.freeze({
    island: 'Molokai',
    location: 'Hoolehua',
    soilOrder: 'Inceptisols', 
    coefficients28: {
        degreeTwo: -6.1127,
        degreeOne: 48.676,
        degreeZero: -31.849
    },
    coefficients70: {
        degreeTwo: -14.948,
        degreeOne: 108.06,
        degreeZero: -126.58
    }
})


export const Locations = [
    OahuPoamohoOxisols,
    OahuWaimanaloMollisols,
    OahuKuniaOxisols,
    HawaiiWaimeaAndisols,
    KauaiLihueOxisols,
    MauiAlaeAndisols,
    MauiKulaAndisols,
    MauiMokuleleMollisols,
    MauiWaiakoaMollisols,
    MolokaiHoolehuaInceptisols
]

const calculateFractionOfAcreSampled = (acreSampled) => {
    // acreSampled is expected as a square foot, and there are 43560 sq ft in an acre
    return acreSampled / 43560
}

const calculateDryWeight = (freshWeight, fractionOfAcreSampled, percentDryMatter) => {
    // percentDryMatter is provided as a value from 0-100
    return (freshWeight/fractionOfAcreSampled) * (percentDryMatter / 100)
}

const calculateTotalN = (dryWeight, totalPercentNFromLab) => {
    // totalPercentNFromLab is provided as a value from 0-100
    return dryWeight * (totalPercentNFromLab / 100)
}

const calculateActualPan = (totalN, percentPan) => {
    // percentPan is provided as a value from 0-100
    return totalN * (percentPan / 100)
}

const calculatePercentPan = (coefficients, totalPercentNFromLab) => {
    // Performs a polynomial calculation based on the provided coefficients
    const {degreeTwo, degreeOne, degreeZero} = coefficients

    return degreeTwo * (totalPercentNFromLab * totalPercentNFromLab) +
        degreeOne * totalPercentNFromLab +
        degreeZero
}

export const calculateResults = (locationInfo, acreSampled, freshWeight, percentDryMatter, totalPercentNFromLab) => {
    const fractionOfAcreSampled = calculateFractionOfAcreSampled(acreSampled)
    const dryWeight = calculateDryWeight(freshWeight, fractionOfAcreSampled, percentDryMatter)
    const totalN = calculateTotalN(dryWeight, totalPercentNFromLab)
    const percentPan28 = calculatePercentPan(locationInfo.coefficients28, totalPercentNFromLab)
    const actualPan28 = calculateActualPan(totalN, percentPan28)
    const percentPan70 = calculatePercentPan(locationInfo.coefficients70, totalPercentNFromLab)
    const actualPan70 = calculateActualPan(totalN, percentPan70)


    return {
        fractionOfAcreSampled,
        dryWeight,
        totalN,
        percentPan28,
        actualPan28,
        percentPan70,
        actualPan70
    }
}

export const calculateEstimatedNFertilizerForNextCrop = (totalNRequirement, actualPan) => {
    return totalNRequirement - actualPan
}