import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import Header from './Header';
import Subheader from './Subheader';
import Instructions from './Instructions';
import LocationSelect from './LocationSelect';
import ResultsCard from './ResultsCard';
import ReferenceTablePoamoho from './ReferenceTablePoamoho';
import ReferenceTableLalamilo from './ReferenceTableLalamilo';
import SponsorLogos from './SponsorLogos';
import Disclaimer from './Disclaimer';

import {calculateResults, calculateEstimatedNFertilizerForNextCrop, Locations} from './calculations';

function TotalNFromLabHelper() {
  return (
    <React.Fragment>
      Collect a 1-2 lb lab-sample from your field sample.
      Immediately send to an analytical lab that will dry and grind the whole 
      sample before testing for total %N and % dry matter. If you don't have 
      lab results, please refer to the <Link href="#typical-results">typical Poamoho / Lalamilo results</Link>.
    </React.Fragment>
  )
}

function DryMatterHelper() {
  return (
    <React.Fragment>
      If you don't have lab results, please refer to the <Link href="#typical-results">typical Poamoho / Lalamilo results</Link>.
    </React.Fragment>
  )
}

export default function App() {

  const [areaSampled, setAreaSampled] = React.useState(1);
  const [freshWeight, setFreshWeight] = React.useState(1.2);
  const [totalPercentFromLab, setTotalPercentFromLab] = React.useState(2);
  const [percentDryMatter, setPercentDryMatter] = React.useState(23);

  const [totalNRequirement, setTotalNRequirement] = React.useState(180);

  const [percentPan28, setPercentPan28] = React.useState(null);
  const [actualPan28, setActualPan28] = React.useState(null);
  const [estimate28, setEstimate28] = React.useState(null);
  
  const [percentPan70, setPercentPan70] = React.useState(null);
  const [actualPan70, setActualPan70] = React.useState(null);
  const [estimate70, setEstimate70] = React.useState(null);

  const [location, setLocation] = React.useState(Locations[0])

  const setLocationIndex = (index) => {
    setLocation(Locations[index])
  }

  const sanitize = (val) => {
    if (isNaN(val) || !isFinite(val)) {
      return 'N/A'
    } else {
      return val
    }
  }

  // TODO: Need to look into this to make sure theres no infinite loop
  React.useEffect(() => {
    let results = calculateResults(location, areaSampled, freshWeight, percentDryMatter, totalPercentFromLab);
    
    // Log results that were calculated for debugging
    //console.log(results);

    let estimate28 = calculateEstimatedNFertilizerForNextCrop(totalNRequirement, results.actualPan28)
    setEstimate28(sanitize(Math.round(estimate28)))
    setPercentPan28(sanitize(results.percentPan28.toFixed(2)));
    setActualPan28(sanitize(Math.round(results.actualPan28)));
    
    let estimate70 = calculateEstimatedNFertilizerForNextCrop(totalNRequirement, results.actualPan70)
    setEstimate70(sanitize(Math.round(estimate70)))
    setPercentPan70(sanitize(results.percentPan70.toFixed(2)));
    setActualPan70(sanitize(Math.round(results.actualPan70)));
  });

  return (
    <React.Fragment>
      <Header />
      <Subheader />
      
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Cover Crop Calculator for Plant Available Nitrogen
          </Typography>
          <Typography sx={{mb: 2}}>
            Cover crops can contribute significant amounts of nitrogen to crop production.
            This app uses plant-available nitrogen mineralization rates from different areas, 
            nitrogen content and biomass production of cover crop residues to estimate the 
            nitrogen contribution from cover crop residues in Hawaii. 
          </Typography>
          <Instructions />
          
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LocationSelect
                age='oahu_poamonho_oxisols'
                locations={Locations}
                onChange={(event) => setLocationIndex(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="filled"
                label="Area"
                helperText="Just before termination, sample above ground cover crop biomass from at least 4 locations in your field: i.e. four 1-ft² quadrants."
                sx={{ mt: 1 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">ft²</InputAdornment>,
                }}
                defaultValue={areaSampled}
                error={isNaN(areaSampled)}
                onChange={(event) => setAreaSampled(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="filled"
                label="Fresh Weight"
                helperText="Combine all quadrant samples. Tear them up by hand and mix them for 1-2 minutes. Weigh the fresh weight of your field sample."
                sx={{ mt: 1 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                }}
                defaultValue={freshWeight}
                error={isNaN(freshWeight)}
                onChange={(event) => setFreshWeight(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="filled"
                label="Total N (%) From Lab"
                helperText={<TotalNFromLabHelper/>}
                sx={{ mt: 1 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                defaultValue={totalPercentFromLab}
                error={isNaN(totalPercentFromLab)}
                onChange={(event) => setTotalPercentFromLab(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="filled"
                label="% Dry Matter From Lab"
                helperText={<DryMatterHelper />}
                sx={{ mt: 1 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                defaultValue={percentDryMatter}
                error={isNaN(percentDryMatter)}
                onChange={(event) => setPercentDryMatter(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                variant="filled"
                label="Total N Requirement"
                helperText="Enter the total N requirement for your crop."
                sx={{ mt: 1 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lb/acre</InputAdornment>,
                }}
                defaultValue={totalNRequirement}
                error={isNaN(totalNRequirement)}
                onChange={(event) => setTotalNRequirement(event.target.value)}
              />
            </Grid>
          </Grid>

          <Divider sx={{py: 2}}>Results</Divider>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ResultsCard
                days={28}
                pan={percentPan28}
                actualPan={actualPan28}
                estimatedNFertilizer={estimate28}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ResultsCard
                days={70}
                pan={percentPan70}
                actualPan={actualPan70}
                estimatedNFertilizer={estimate70}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="text.secondary">
                This section provides you with the estimate N fertilizer 
                needed for your crop. Compare your cover crop results with 
                UH ranges (found below in the <Link href="#typical-results">Reference Data</Link> portion of this page). 
                Use caution if your estimates are unusual.
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{py: 2}} id="typical-results">Reference Data</Divider>

          <Box sx={{py:3}}>
            <Typography variant="h5" component="h2" gutterBottom>
              Plant Available N of Typical Cover Crops Used in Lower Elevation in Hawaii (e.g. Poamoho)
            </Typography>
            <ReferenceTablePoamoho></ReferenceTablePoamoho>
          </Box>
          
          <Box sx={{py:3}}>
            <Typography variant="h5" component="h2" gutterBottom>
              Plant Available N of Typical Cover Crops Used in Upper Elevation in Hawaii (e.g. Lalamilo)
            </Typography>
            <ReferenceTableLalamilo></ReferenceTableLalamilo>
          </Box>

        </Box>
      </Container>

      <SponsorLogos />
      <Disclaimer />
    </React.Fragment>
  );
}
