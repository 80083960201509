import Box from '@mui/material/Box';

export default function Disclaimer() {
  return (
    <Box sx={{ bgcolor: '#2b2b2b', color: '#b3b3b3', textAlign: 'center', p: 6 }} component="footer" typography="body2">
      <Box sx={{py: 1}}>
        <span>
          Developed by Wang, K.-H.<sup>z</sup>,
          N. Andrew<sup>y</sup>,
          S. Ching<sup>z</sup>,
          J. Marquez<sup>z</sup>, 
          A. Pant,
          T. Radovich<sup>z</sup>, 
          P. Waisen<sup>z</sup>, 
          D. Sullivan<sup>y</sup>, and
          J. Uyeda<sup>z</sup> in March 2017. 
        </span><br />
        <span><sup>z</sup>University of Hawaii</span>, <sup>y</sup>Oregon State University<br />
        <span><sup>x</sup>CRATE (Center for Rural Agricultural Training and Entrepreneurship) is an USDA NIFA funded project (2013-04774) aimed to explore competitive and economically viable organic farming practices for local and immigrant farmers in Hawaii. Cover crop calculator for Hawaii is sponsored by USDA NRCS CIG (69-3A75-14-231), Western Sustainable Agriculture Research and Education WESP 19-01, NIFA OREI (HAW09705-G), CTAHR Hatch (HAW9048-H) and Plan of Work (POW 16-964 and 22-015).</span>
      </Box>
      <Box sx={{py: 1}}>
        <span>This material is based upon work that is supported by the National Institute of Food and Agriculture, U.S. Department of Agriculture, under award number 2018-38640-28418 through the Western Sustainable Agriculture Research and Education program under project number WESP 19-01. USDA is an equal opportunity employer and service provider.  Any opinions, findings, conclusions, or recommendations expressed in this publication are those of the author(s) and do not necessarily reflect the view of the U.S. Department of Agriculture.</span>
      </Box>
      <Box sx={{py: 1}}>
        <span>Disclaimer: Cover Crop Calculator estimates % PAN based on cover crop %N and mineralization rates at different locations. Accuracy of predictions may vary dependening on climate, season, time after cover crop termination, farming practices, and soil microbial activity.</span><br />
      </Box>
    </Box>
  );
}