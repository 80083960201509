import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const steps = [
  {
    label: 'Field Sample',
    description: `Just before termination, sample above ground cover crop biomass
        from at least 4 locations in your field: i.e. four 1-ft² quadrants.`,
  },
  {
    label: 'Fresh Weight',
    description: `Combine all quadrant samples. Tear the plant samples up by hand
        and mix for 1-2 minutes. Weigh the total fresh biomass of your field sample.`,
  },
  {
    label: 'Record',
    description: `Record the area sampled and fresh weight of your field sample.`,
  },
  {
    label: 'Lab Sample',
    description: `Collect a 1-2 lb of your biomass sample for lab analysis by 
        sending the sample to an analytical lab that will dry and grind the whole 
        sample before testing for total %N and % dry matter.`,
  },
  {
    label: 'Cover Crop Calculator',
    description: `Enter area sampled, fresh weight of biomass, total %N, and % 
        dry matter in this website.`,
  },
  {
    label: 'Interpreting Results',
    description: `The results section provides you with the estimate N fertilizer
        needed for your crop. Compare your cover crop results with UH ranges (found
        below in the "Reference Data" portion of this page). Use caution if your
        estimates are unusual.`,
  },
  {
    label: 'Monitor Crops & Soil',
    description: (
      <React.Fragment>
        Look for crop N deficiencies and monitor soil nitrate levels.
        Supplement with additional fertilizer if needed.
    
        For more information on Cover Crop Research, <Link href="https://cms.ctahr.hawaii.edu/wangkh/Research-and-Extension/Cover-Crops">click here</Link>.
      </React.Fragment>
    ),
  },
];

export default function VerticalLinearStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const {onFinished} = props

  const handleNext = () => {
    // special case handling to call the finished on the last step
    if (activeStep + 1 == steps.length) {
      onFinished();
    } else {
      setActiveStep((prevActiveStep) => {
        return prevActiveStep + 1;
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {step.label}
            </StepButton>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
