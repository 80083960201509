import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function DataPoint(props) {
  const {value, units, description} = props;

  return (
    <React.Fragment>
      <Typography component="p" variant="h5" sx={{  }}>
        {value} {units}
      </Typography>
      <Typography color="text.secondary" variant="body2" sx={{ flex: 1, pb: 2 }}>
        {description}
      </Typography>
  </React.Fragment>
  )
}

export default function ResultsCard(props) {
  let {days, pan, actualPan, estimatedNFertilizer} = props;
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {days} Day Estimation
        </Typography>
        <DataPoint
          value={pan}
          units={`%`}
          description={'PAN'}
        />
        <DataPoint
          value={actualPan}
          units={`lb/acre`}
          description={'Actual PAN'}
        />
        <DataPoint
          value={estimatedNFertilizer}
          units={`lb/acre`}
          description={'Estimated N Fertilizer for Next Crop'}
        />
      </CardContent>
    </Card>
  );
}

