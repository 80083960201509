import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import usda from './images/sponsors/usda.png';
import nifa from './images/sponsors/nifa.png';
import western_sare from './images/sponsors/western_sare.png';
import nrcs from './images/sponsors/nrcs.png';
import spm from './images/sponsors/spm.png';
import crate from './images/sponsors/crate.png';
import soap from './images/sponsors/soap.png';

export default function SponsorLogos() {
  return (
    <Container maxWidth="xl">
      <Box sx={{ bgcolor: 'white', pt: 6, textAlign: 'center'}} component="footer" typography="body2">
        <Box component="img"
          src={usda}
          alt={`USDA`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
        <Box component="img"
          src={nifa}
          alt={`NIFA`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
        <Box component="img"
          src={western_sare}
          alt={`WESTERN SARE`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
        <Box component="img"
          src={nrcs}
          alt={`NRCS SARE`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
        <Box component="img"
          src={spm}
          alt={`Sustainable Pest Management Lab`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
        <Box component="img"
          src={crate}
          alt={`CRATE`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
        <Box component="img"
          src={soap}
          alt={`Sustainable and Organize Agriculture Program`}
          loading="lazy"
          sx={{ objectFit: `contain`, height: {xs: '58px', sm: '90px', md: '145px'} }}
        />
      </Box>
    </Container>
  );
}