import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffaf44',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, coverCrop, freshWeight, dryContent, dryWeight, tissueN, totalN, pan28, actualPan28, pan70, actualPan70, bg, fontWeight) {
  return { name, coverCrop, freshWeight, dryContent, dryWeight, tissueN, totalN, pan28, actualPan28, pan70, actualPan70, bg, fontWeight };
}

const rows = [
  createData('Winter/Till', 'Sunn hemp', '1.2', '23.10%', '12074.83', '1.66', '200.44', '55.24', '110.72', '67.82', '135.94'),
  createData('Winter/Till', 'Cowpea (Blackeye #5)', '1.04', '13.70%', '6206.43', '2.87', '178.12', '63.74', '113.54', '75.17', '133.90'),
  createData('Winter/Till', 'Lablab', '0.9', '14.89%', '5837.48', '2.75', '160.53', '62.72', '100.68', '75.22', '120.75'),
  createData('Winter/Till', 'Pigeon pea', '0.55', '20.47%', '4904.20', '3.47', '170.18', '66.14', '112.55', '81.69', '139.02'),
  createData('Winter/Till', 'Woolypod vetch', '0.55', '9.21%', '2206.53', '4.43', '97.75', '70.52', '68.93', '84.19', '82.30'),  

  createData('Average', '', '0.848', '16.27%', '', '3.036', '', '63.672', '', '76.818', '122.38', '#f1a896', 'bold'),

  createData('Winter/No-till', 'Sunn hemp', '1.07', '24.62%', '11475.19', '2', '229.50', '56.85', '130.47', '66.72', '153.12'),
  createData('Winter/No-till', 'Cowpea (Blackeye #5)', '1.47', '14.20%', '9092.71', '2', '181.85', '56.6', '102.93', '65.42', '118.97'),
  createData('Winter/No-till', 'Lablab', '1.02', '16.20%', '7197.85', '2.02', '145.40', '56.38', '81.97', '66.28', '96.37'),
  createData('Winter/No-till', 'Pigeon pea', '0.58', '21.99%', '5555.73', '2.31', '128.34', '60.11', '77.14', '67.32', '86.40'),
  createData('Winter/No-till', 'Woolypod vetch', '0.83', '8.80%', '3181.62', '2.92', '92.90', '62.08', '57.67', '70.43', '65.43'),  

  createData('Average', '', '0.994', '17.16%', '', '2.25', '', '58.404', '', '67.234', '104.06', '#f1a896', 'bold'),

  createData('Summer/No-till', 'Sunn hemp', '0.72', '21.34%', '6692.91', '2.72', '182.05', '60.54', '110.21', '75.14', '136.79'),
  createData('Summer/No-till', 'Cowpea', '1.54', '14.24%', '9552.53', '2.83', '270.34', '67.57', '182.67', '74.43', '201.21'),
  createData('Summer/No-till', 'Lablab', '0.34', '13.31%', '1971.26', '3.13', '61.70', '78.05', '48.16', '81.91', '50.54'),
  createData('Summer/No-till', 'Sudex', '0.96', '16.02%', '6699.18', '1.33', '89.10', '43.48', '38.74', '54.95', '48.96'),
  createData('Summer/No-till', 'Oat (TAM406)', '0.51', '14.72%', '3270.14', '1.84', '60.17', '46.25', '27.83', '62.55', '37.64'),
  createData('Summer/No-till', 'Oil Radish', '0.55', '6.40%', '1533.31', '2.49', '38.18', '70.8', '27.03', '77', '29.40'),  
  
  createData('Average', '', '0.78', '14.34%', '', '2.39', '', '61.115', '', '71.00', '84.09', '#f1a896', 'bold'),
  
  createData('Average (Overall)', '', '', '15.83%', '', '2.55', '', '61.07', '86.95', '71.64', '102.30', '#dededa', 'bold'),
];

export default function ReferenceTablePoamoho() {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
      <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table" size='small'>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan={4}>Cover Cropping Practice</StyledTableCell>
            <StyledTableCell align="center" colSpan={3}>Cover Cropping Tissue</StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>28 Days</StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>70 Days</StyledTableCell>
          </TableRow>
          
          <TableRow sx={{backgroundColor: '#ffefd9'}}>
            <StyledTableCell>Season/tillage</StyledTableCell>
            <StyledTableCell align="right">Cover Crop</StyledTableCell>
            <StyledTableCell align="right">Fresh Weight&nbsp;(lb/ft<sup>2</sup>)</StyledTableCell>
            <StyledTableCell align="right">Dry Content&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Dry Weight&nbsp;(lb/Acre)</StyledTableCell>
            <StyledTableCell align="right">Tissue N&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Total N&nbsp;(lb/A)</StyledTableCell>
            <StyledTableCell align="right">PAN&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Actual PAN&nbsp;(lb/A)</StyledTableCell>
            <StyledTableCell align="right">PAN&nbsp;(%)</StyledTableCell>
            <StyledTableCell align="right">Actual PAN&nbsp;(lb/A)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i} sx={{backgroundColor: row.bg, fontWeight: row.fontWeight}}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.coverCrop}</StyledTableCell>
              <StyledTableCell align="right">{row.freshWeight}</StyledTableCell>
              <StyledTableCell align="right">{row.dryContent}</StyledTableCell>
              <StyledTableCell align="right">{row.dryWeight}</StyledTableCell>
              <StyledTableCell align="right">{row.tissueN}</StyledTableCell>
              <StyledTableCell align="right">{row.totalN}</StyledTableCell>
              <StyledTableCell align="right">{row.pan28}</StyledTableCell>
              <StyledTableCell align="right">{row.actualPan28}</StyledTableCell>
              <StyledTableCell align="right">{row.pan70}</StyledTableCell>
              <StyledTableCell align="right">{row.actualPan70}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
